<template>
  <section>
    <a-row type="flex" justify="space-between" class="container">
      <div class="weather" style="height: 100%; width: 29%" :left="'0px'">
        <!-- <p></p> -->

        <BaseContentBox style="height: 45%" :title="'天气数据'">
          <a-col :span="24" class="weather_box m-b-10">
            <a-row type="flex" justify="space-between">
              <a-col
                :span="8"
                :class="{borderLine:item.isActive}"
                v-for="(item, ind) in futureThreeDaysData"
                :key="ind"
              >
                <p class="m-b-16 m-t-16">
                  {{ item.days }}
                  <span style="font-size: 12px">{{ item.week }}</span>
                </p>
                <!-- <p class="m-b-16">农历七月初六</p> -->
                <!-- <img class="m-b-16" :src="item.weather_icon" alt style="width: 44px" /> -->
                <WeatherIcon :icon="item.weatherIndex" :size="44"></WeatherIcon>

                <p class="m-b-16">
                  <span class="weather_num">{{ item.temp_high }}</span>
                  <span class="weather_unit">℃</span>
                </p>
                <p class="m-b-16">{{ item.temperature }}</p>
                <p class="m-b-16">
                  <span class="status excellent">优</span>
                </p>
                <p class="m-b-16" style="font-size: 10px">{{ item.wind }}</p>
              </a-col>
            </a-row>
          </a-col>
        </BaseContentBox>
        <BaseContentBox :title="'历史异常天气汇总'" :width="180" style="height: 53%;margin-top:10px">
          <a-col :span="24" class="weather_box m-b-10">
            <!-- <img class="title_bg" src="../../assets/image/bg-image/unusual_weather.png" alt /> -->
            <p style="height:10px;font-size:10px;padding-right:56px">
              <span style="float:right;font-size:10px;line-height:10px">&nbsp;天数</span>
              <span class="redDiv"></span>
            </p>
            <history-error-weather style="width: 100%; height: 80%;margin-top:10px" />
          </a-col>
        </BaseContentBox>
      </div>

      <a-col style="height: 100%; width: 41%">
        <BaseContentBox
          class="monitor"
          type="map"
          :left="'0px'"
          :title="'环境监控'"
          style="height:calc(98% + 10px)"
        >
          <li class="tab_li">
            <a
              style="margin-left:5px"
              :class="
                currentDeviceEnviCurData.deviceCode === item.deviceCode
                  ? 'status'
                  : ''
              "
              v-for="(item, ind) in deviceEnviCurDataMap"
              :key="ind"
              @click="tabChange(ind)"
            >{{ item.deviceName }}</a>
          </li>
          <section class="monitor_top">
            <section class="pass">
              <section>
                <p style="font-size: 28px; color: #fff; margin-bottom: 2px">
                  <span>{{ currentDeviceEnviCurData.noise || 0 }}</span>
                  <span style="font-size: 18px">
                    {{
                    currentDeviceEnviCurData.noiseUnit
                    }}
                  </span>
                </p>
                <p class="m-b-8" style="color: #fff">噪声</p>
              </section>
            </section>
            <section class="fail">
              <section>
                <p style="font-size: 28px; color: #fff; margin-bottom: 2px">
                  <span>{{ currentDeviceEnviCurData.windDirection || 0 }}</span>
                  <span style="font-size: 18px">
                    {{
                    currentDeviceEnviCurData.windDirectionUnit
                    }}
                  </span>
                </p>
                <p class="m-b-8" style="color: #fff">风向</p>
              </section>
            </section>
            <section class="pass pass2">
              <section>
                <p style="font-size: 28px; color: #fff; margin-bottom: 2px">
                  <span>{{ currentDeviceEnviCurData.pmtwo || 0 }}</span>
                  <span style="font-size: 18px">
                    {{
                    currentDeviceEnviCurData.pmtwoUnit
                    }}
                  </span>
                </p>
                <p class="m-b-8" style="color: #fff">PM2.5</p>
              </section>
            </section>
            <section class="pass pass3">
              <section>
                <p style="font-size: 28px; color: #fff; margin-bottom: 2px">
                  <span>{{ currentDeviceEnviCurData.pmten || 0 }}</span>
                  <span style="font-size: 18px">
                    {{
                    currentDeviceEnviCurData.pmtenUnit
                    }}
                  </span>
                </p>
                <p class="m-b-8" style="color: #fff">PM10</p>
              </section>
            </section>
            <section class="pass pass4">
              <section>
                <p style="font-size: 28px; color: #fff; margin-bottom: 2px">
                  <span>{{ currentDeviceEnviCurData.speed || 0 }}</span>
                  <span style="font-size: 18px">
                    {{
                    currentDeviceEnviCurData.speedUnit
                    }}
                  </span>
                </p>
                <p class="m-b-8" style="color: #fff">风速</p>
              </section>
            </section>
            <section class="monitor_pic">
              <img class="banner-1" src="../../assets/image/bg-image/banner-1.png" alt />
              <img class="banner-2" src="../../assets/image/bg-image/banner-2.png" alt />
              <img class="banner-3" src="../../assets/image/bg-image/banner-3.png" alt />
              <section>
                <p
                  class="m-b-8"
                  style="font-size: 40px; color: rgba(0, 146, 255, 1);font-family:'fontquan'"
                >{{aqiText}}</p>
                <p class="m-b-8" style="font-size:20px">{{aqi}}</p>
                <p class="m-b-8">空气质量得分</p>
              </section>
            </section>
          </section>
          <section class="monitor_bottom">
            <section class="status_list">
              <li class="color_list">
                <span style="background: rgba(64, 146, 255, 1)">
                  <b style="top: -19px; left: 0">0</b>
                  <b style="top: -19px; right: -5px">50</b>
                </span>
                <span style="background: rgba(255, 187, 56, 1)">
                  <b style="top: -19px; right: -5px">100</b>
                </span>
                <span style="background: rgba(253, 121, 22, 1)">
                  <b style="top: -19px; right: -5px">150</b>
                </span>
                <span style="background: rgba(252, 24, 93, 1)">
                  <b style="top: -19px; right: -5px">200</b>
                </span>
                <span style="background: rgba(171, 116, 255, 1)">
                  <b style="top: -19px; right: -5px">300</b>
                </span>
                <span
                  style="
                    background: rgba(99, 13, 0, 1);
                    color: rgba(99, 13, 0, 1);
                  "
                >
                  <b style="top: -19px; right: -5px; color: #fff">∞</b>
                </span>
              </li>
            </section>
            <section class="status_list" style="margin-top: 0; height: 10px">
              <li>
                <span>优</span>
                <span>良</span>
                <span>轻度污染</span>
                <span>中度污染</span>
                <span>重度污染</span>
                <span>严重污染</span>
              </li>
            </section>
          </section>
        </BaseContentBox>

        <!-- <section class="workingWarm scroll-container">
          <BaseContentBox :title="'施工提醒'">
            
          </BaseContentBox>
        </section>-->
      </a-col>
      <a-col style="height: 100%; width: 29%">
        <div class="green" :left="'0px'" style="height: 100%">
          <BaseContentBox style="height: 53%;" :title="'施工提醒'">
            <div style="height:100%;overflow: hidden!important">
              <vue-seamless-scroll :data="reminderList" :class-option="classOption">
                <!-- <p></p> -->
                <li v-for="(item,index) in reminderList" :key="index" style="padding:10px ">
                  <img
                    class="m-r-10"
                    style="width: 20px"
                    src="../../assets/image/bg-image/warm_tip_icon.png"
                    alt
                  />
                  {{item}}
                </li>
              </vue-seamless-scroll>
            </div>
            <!-- <section>
              <early-warning-type style="width: 100%; height: 180px" />
            </section>-->
          </BaseContentBox>

          <BaseContentBox :title="'近24小时数据分析'" :width="180" style="height: 45%;margin-top:10px">
            <a-col :span="24" class="weather_box m-b-10">
              <!-- <img class="title_bg" src="../../assets/image/bg-image/24houer_weather.png" alt /> -->

              <a-row style="text-align: right">
                <a
                  @click="activeKey = item.key"
                  :style="{
                color:
                  item.key == activeKey ? '#1890ff' : 'rgba(255,255,255,0.8)',
              }"
                  v-for="item in typeList"
                  :key="item.key"
                  class="m-r-16"
                >{{ item.title }}</a>
              </a-row>
              <hours-data-analysis
                :activeKey="activeKey"
                style="width: 100%; height: 90%;padding:10px 0 0 0"
              />
            </a-col>
          </BaseContentBox>
        </div>

        <!-- <BaseContentBox :left="'0px'" :title="'设备预警记录'" class="record" style="height: 41%">
          <div style="width: 100%; text-align: center; height: 95%">
            <li
              class="flex-bet"
              style="
                color: #95adce;
                display: flex;
                justify-content: space-between;
                text-align: center;
              "
            >
              <span style="flex: 1">事件名称</span>
              <span style="flex: 1">事件描述</span>
              <span style="flex: 1">预警事件</span>
              <span style="flex: 1">预警地点</span>
              <span style="flex: 1">阈值</span>
            </li>
            <div style="overflow: hidden; height: 90%">
              <div style="height: 100%" class="anim">
                <textData :length="7" :textData="deviceEnviWarnLists">
                  <template v-slot:key="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; height: 30px; line-height: 30px"
                    >{{ slotProps.val }}</span>
                  </template>
                  <template v-slot:warnName="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; height: 30px; line-height: 30px;"
                    >{{ slotProps.val }}</span>
                  </template>
                  <template v-slot:warnDesc="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; height: 30px; line-height: 30px"
                    >{{ slotProps.val }}</span>
                  </template>
                  <template v-slot:warnLocation="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; height: 30px; line-height: 30px"
                    >{{ slotProps.val }}</span>
                  </template>
                  <template v-slot:threshold="slotProps">
                    <span
                      class="text-overflow"
                      style="flex: 1; height: 30px; line-height: 30px"
                    >{{ slotProps.val }}</span>
                  </template>
                </textData>
              </div>
            </div>
          </div>
        </BaseContentBox>-->
      </a-col>
    </a-row>
  </section>
</template>
<script>

import VueSeamlessScroll from 'vue-seamless-scroll'
// import earlyWarningType from "./components/earlyWarningType"; //预警类型
// import earlyWarningLevel from "./components/earlyWarningLevel"; //预警等级
import historyErrorWeather from "./components/historyErrorWeather"; //历史异常天气
import hoursDataAnalysis from "./components/hoursDataAnalysis"; //历史异常天气
// import boxTitleMoudle from "@/components/BaseContentBox.vue";
// import textData from "@/components/textData.vue";
import BaseContentBox from "./components/BaseContentBox.vue"
import WeatherIcon from '@/components/WeatherIcon'
export default {
  components: {
    VueSeamlessScroll,
    // earlyWarningType,
    // earlyWarningLevel,
    historyErrorWeather,
    hoursDataAnalysis,
    // boxTitleMoudle,
    // textData,
    BaseContentBox,
    WeatherIcon
  },
  data() {
    return {
      deviceEnviWarnLists: [],
      futureThreeDaysData: [],
      deviceEnviCurDataMap: [],
      times:null,
      currentDeviceEnviCurData: {},
      typeList: [
        { title: "PM2.5", key: 0 },
        { title: "PM10", key: 1 },
        { title: "风速", key: 2 },
      ],
      //阀值
      thresholdMap: [
        { type: 1, typeName: "温度", value: "27℃" },
        { type: 2, typeName: "湿度", value: "60%" },
        { type: 3, typeName: "风速", value: "10.8m/s" },
      ],
      activeKey: 0,
      animate: false,
      spliceList: [],
      sliceData: [],
      ind: 0,
      scrollTime: null,
      reminderList: [],//提醒列表
      aqi: '55',
      aqiText: '优'
    };
  },
  created() {
    // this.queryDeviceEnviWarnData();//获取设备预警记录
    this.queryDeviceEnviCurData();//获取中间板块
    this.queryDeviceWeatherDataFuture();//获取未来3天天气
    this.getConstructionReminders()//施工提醒列表
    this.getDeviceWeatherData();//获取当天天气
    // setInterval(this.scroll, 1000);


    this.times = setInterval(()=>{
      this.queryDeviceEnviCurData();//获取中间板块
      this.queryDeviceWeatherDataFuture();//获取未来3天天气
      this.getConstructionReminders()//施工提醒列表
      this.getDeviceWeatherData();//获取当天天气
    },5*1000)
  },
  destroyed(){
    clearInterval(this.times)
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快computed
        limitMoveNum: this.reminderList.length, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 26, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    tabChange(key) {
      this.currentDeviceEnviCurData = this.deviceEnviCurDataMap.length
        ? this.deviceEnviCurDataMap[key]
        : {};
    },
    // 分割数组
    sliceArr(arr, len) {
      let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
      for (let i = 0; i < arr.length;) {
        //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, (i += len)));
      }
      return newArr;
    },
    // 获取中间板块
    queryDeviceEnviCurData() {
      this.$api.queryDeviceEnviCurData().then((res) => {
        console.log('2222222222', res);
        if (res.code === 200) {
          this.deviceEnviCurDataMap = res.data || [];
          this.currentDeviceEnviCurData = this.deviceEnviCurDataMap.length
            ? this.deviceEnviCurDataMap[0]
            : {};
        }
      });
    },
    //未来3天天气
    queryDeviceWeatherDataFuture() {
      this.$api.queryDeviceWeatherDataFuture().then((res) => {
        if (res.code === 200) {
          this.futureThreeDaysData = res.data || [];
          if (this.futureThreeDaysData && this.futureThreeDaysData.length) {

            this.futureThreeDaysData.forEach((v, index) => {
              v.weatherIndex = (Number(v.weather_iconid) + 1).toString()

              if (index != this.futureThreeDaysData.length - 1) {

                v.isActive = true
              }
              else {

                v.isActive = false
              }
            })
            this.futureThreeDaysData.splice(0, 1)
          }
        }
      });
    },
    //天气
    getDeviceWeatherData() {
      this.$api.getDeviceWeatherData().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.aqi = res.data.aqi ? Number(res.data.aqi) : 55;
          if (this.aqi >= 0 && this.aqi <= 50) this.aqiText = '优';
          else if (this.aqi > 50 && this.aqi <= 100) this.aqiText = '良';
          else if (this.aqi > 100 && this.aqi <= 150) this.aqiText = '轻度';
          else if (this.aqi > 150 && this.aqi <= 200) this.aqiText = '中度';
          else if (this.aqi > 200 && this.aqi <= 300) this.aqiText = '重度';
          else this.aqiText = '严重';
        }
      })
    },
    //施工提醒列表
    getConstructionReminders() {
      //   this.$api.getConstructionReminders().then((res) => {
      //     if (res.code === 200) {
      //       this.reminderList = res.data || [];
      //     }
      //   });
      this.reminderList = [
        '安全来于警惕，事故出于麻痹',
        '以事故为镜可知隐患无情，以教训为镜可知安全重要',
        '依法监管，公正执法，确保安全',
        '严明纪律，禁止随意妄为；遵守规章，禁止冒险蛮干',
        '严格质量，优良出品',
        '严是爱，松是害，搞好安全利三代',
        '精心施工建精品，与时俱进创辉煌',
        '科学管理，施工规范',
        '安全生产，人人有责',
        '抓好廉政建设，建设优质工程',
        '镜子不擦拭不明，事故不分析不清',
        '从严管理、扎实工作、确保质量',
        '安全第一，质量为本',
        '安全帽必须戴，还要扣好系帽带，防止干活出意外',
        '要认真抓好安全生产责任制的落实，稳步促进安全形式好转',
        '不讲安全搭上命，挣座金山有啥用',
        '广泛动员，强化监督，责任到位，常抓不懈',
        '蛮干是走向事故深渊的第一步',
        '爱家就是爱生命，重情更应重安全',
        '违章是拿生命作不必要的冒险，请你严格遵守安规',
        '忽视安全抓生产似火中取栗，不顾安全求效益如水中捞月',
        '创一流施工业绩，树文明施工新风',
        '传播安全文化,宣传安全知识',
        '抓好安全生产,促进经济发展',
        '聪明人把安全寄托在遵章上,糊涂人把安全依赖在侥幸上',
        '安全是家庭幸福生活的保障.疏忽大意是事故滋生的温床',
        '绊人的桩不在高,违章的事不在小',
        '广泛开展安全活动,深入贯彻安全生产法',
        '作业时戴好安全帽,流汗总比流血好',
        '为了爱你的家人,朋友,同事,请时时刻刻注意安全',
        '加强质量管理,建设优质工程',
        '我们的城市有你的血汗,你的平安是我们的心愿  ',
      ]
    },
    queryDeviceEnviWarnData() {
      this.$api.getEnvironmentWarns().then((res) => {
        if (res.code == 200) {

          this.deviceEnviWarnLists = res.data || [];

          //   console.log('deviceEnviWarnLists', this.deviceEnviWarnLists);
          let mapList = [];
          this.deviceEnviWarnLists.forEach((item, ind) => {
            mapList.push({
              key: ind + 1,
              warnName: item.warnName,
              warnDesc: item.warnDesc,
              warnLocation: item.warnLocation,
              threshold: item.threshold
            });
          });
          this.deviceEnviWarnLists = mapList;

        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-overflow {
  white-space: nowrap; /*强制单行显示*/
  text-overflow: ellipsis; /*超出部分省略号表示*/
  overflow: hidden; /*超出部分隐藏*/
}
.app-container {
  margin-top: 10px;
}
// .anim {
//   transition: all 0.5s;
//   margin-top: -236px;
// }
.container {
  height: 100%;
  // 天气
  .weather {
    .title_bg {
      width: 100%;
    }
    .weather_box {
      text-align: center;
      box-sizing: border-box;
      padding: 10px 0;
      .borderLine {
        border-right: 1px dashed #3f72ba;
      }
      .weather_num {
        font-size: 30px;
        color: #fff;
      }
      .weather_unit {
        vertical-align: top;
      }
      .status {
        display: inline-block;
        width: 50%;
        color: #fff;
        border-radius: 3px;
      }
      .excellent {
        background-color: #80cd7f;
      }
      .medium {
        background-color: #837fcd;
      }
      .bad {
        background-color: #c1844d;
      }
    }
  }
  // 监控
  .monitor {
    height: 65%;

    .tab_li {
      color: rgba(210, 210, 210, 1);
      span,
      a {
        color: #ffffff;
        margin-right: 20px;
        cursor: pointer;
      }
      .status {
        color: #84ffe9;
        &::before {
          content: "";
          width: 8px;
          display: inline-block;
          height: 8px;
          background: #84ffe9;
          margin-right: 8px;
          border-radius: 1px;
        }
      }
    }
    // 转动动画

    @keyframes rot {
      from {
        -webkit-transform: rotate(0deg);
      }

      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes rot1 {
      from {
        -webkit-transform: rotate(360deg);
      }

      to {
        -webkit-transform: rotate(0deg);
      }
    }

    .monitor_top {
      width: 100%;
      height: 70%;
      position: relative;
      display: flex;
      justify-content: center;
      .monitor_pic {
        width: 367px;
        height: 367px;
        text-align: center;
        margin-top: 20px;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        // background: url("../../assets/image/bg-image/monitor_pic.png") no-repeat;
        // background-size: 100% 100%;
        position: relative;
        top: 10%;
        user-select: none;
        .banner-1 {
          position: absolute;
          animation: rot 30s linear infinite;
          top: 0%;
        }
        .banner-2 {
          position: absolute;
          width: 86%;
          animation: rot1 30s linear infinite;
          top: 6%;
        }
        .banner-3 {
          position: absolute;
          width: 66%;
          animation: rot 30s linear infinite;
          top: 16%;
        }
      }
      .pass {
        width: 100px;
        height: 97px;
        text-align: center;
        position: absolute;
        left: 11%;
        top: 3%;
        background: url("../../assets/image/bg-image/pass.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        color: #fff !important;
        font-family: "huangyouti";
        // animation: icon 1s linear infinite;
        user-select: none;
      }
      .fail {
        width: 100px;
        height: 97px;
        position: absolute;
        left: 11%;
        top: 80%;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        text-align: center;
        color: #fff !important;
        background: url("../../assets/image/bg-image/pass.png") no-repeat;
        // background: url("../../assets/image/bg-image/fail.png") no-repeat;
        background-size: 100% 100%;
        // animation: icon 1s linear infinite;
        user-select: none;
      }
      @keyframes icon {
        0% {
          opacity: 1;
          transform: translate(0, 0);
        }
        50% {
          opacity: 1;
          transform: translate(3px, 3px);
        }
        100% {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
      .pass2 {
        left: 3%;
        top: 41%;
        // animation: icon 1s linear infinite;
      }
      .pass3 {
        left: 81%;
        top: 55%;
        // animation: icon 1s linear infinite;
      }
      .pass4 {
        left: 81%;
        top: 5%;
        // animation: icon 1s linear infinite;
      }
    }
    .monitor_bottom {
      margin-top: 40px;
      width: 100%;
      background: url("../../assets/image/bg-image/monitor_bottom.png")
        no-repeat;
      background-size: 100% 100%;
      height: 15%;
      // display: flex;
      // justify-content: center; /* 水平居中 */
      // align-items: center; /* 垂直居中 */
      .status_list {
        width: 100%;
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */

        li {
          text-align: center;
          width: 70%;
          margin-top: 20px;
          display: flex;
          height: 10px;
          span {
            flex: 1;
          }
        }
      }
      .color_list {
        span {
          position: relative;
          b {
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }

  // 施工提醒
  .workingWarm {
    height: 30%;
    margin-top: 10px;
    // background: url("../../assets/image/bg-image/working_warm.png") no-repeat;
    background-size: 100% 100%;
    // padding: 10px 20px;
  }

  // 绿色施工
  .green {
    height: 62%;
    position: relative;
    .title_bg {
      width: 100%;
    }
    .des {
      position: absolute;
      right: 26px;
      top: 79px;
    }
  }

  .record {
    margin-top: 20px;
    .flex-bet {
      display: flex;
      justify-content: space-between;
      text-align: center;
      height: 30px;
      line-height: 30px;

      span {
        flex: 1;
      }
    }
    .safeData {
      background: rgba(39, 73, 121, 1);
      margin-top: 4px;
      height: 14.6%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
p {
  // margin-bottom: 0;
  color: #d2d2d2;
}
li {
  list-style: none;
}
.redDiv {
  float: right;
  width: 10px;
  height: 5.5px;
  opacity: 1;
  margin-right: 7px;
  margin-top: 2px;
  background-color: #cc3a3a;
  transform: skew(-30deg);
}
</style>