<template>
  <div id="history-error-weather"></div>
</template>

<script>
export default {
  data() {
    return {
      times:null,
    }
  },
  mounted() {
    this.queryHistoryErrorWeatherData()
    this.times = setInterval(()=>{
      this.queryHistoryErrorWeatherData();
    },5*1000)
  },
  destroyed(){
    clearInterval(this.times)
  },
  methods: {
    queryHistoryErrorWeatherData() {
      this.$api.queryDeviceWeatherData().then(res => {
        if (res.code === 200) {
          let list = res.data || []
          this.renderChart(list)
        }
      })
    },
    renderChart(list) {
      let myChart = this.$echarts.init(
        document.getElementById("history-error-weather")
      );

      myChart.setOption({
        tooltip: {},
        radar: {
          indicator: list.map(item => {
            return {
              name: item.typeName,
              max: Math.max.apply(null, list.map(item => item.count)),
              //   max: 2,
              color: '#cbcccd'
            }
          }),
          radius: 130,
          shape: 'circle',
          //层级
          splitNumber: 3,
          splitArea: {
            areaStyle: {
              //   backgroundColor: ['#4b5768', '#384557', '#202c3e'],
              color: ['#488cc7', '#3480c3', '#1b70ba'],
            }
          },
          //虚线
          axisLine: {
            lineStyle: {
              color: '#2a8ac6',
              type: 'dashed'
            }
          },
          //边框
          splitLine: {
            lineStyle: {
              color: 'rgba(211, 253, 250, 0.8)',
              width: '0.1'
            }
          }
        },
        series: [{
          type: "radar",
          data: [{
            value: list.map(item => item.count),
            name: '历史异常天气次数'
          }],
          areaStyle: {
            color: "rgba(255,0,0,0.2)",
          }
        }]
      });
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    }
  }
}
</script>