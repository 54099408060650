<template>
  <div id="hours-data-analysis"></div>
</template>

<script>
export default {
  data() {
    return {
      resourceData: this.initData(),
      type: ['pmtwo', 'pmten', 'speed'],
      times:null,
    }
  },
  props: ["activeKey"],
  mounted() {
    this.queryDeviceHoursEnviData();
    this.times = setInterval(()=>{
      this.queryDeviceHoursEnviData();
    },5*1000)
    // console.log(this.resourceData)
  },
  destroyed(){
    clearInterval(this.times)
  },
  methods: {
    initData() {
      let arr = []
      for (let i = 0; i < 24; i++) {
        arr.push({
          hour: i < 10 ? '0' + i : '' + i,
          pmtwo: 0,
          pmtwoUnit: 'ug/m3',
          speed: 0,
          speedUnit: 'm/s',
          pmten: 0,
          pmtenUnit: 'ug/m3'
        })
      }
      return arr
    },
    queryDeviceHoursEnviData() {
      this.$api.queryDeviceHoursEnviData({ size: 24 }).then(res => {
        if (res.code === 200) {
          let records = (res.data && res.data.records) || []
          let resourceData = [...this.resourceData];
          records.forEach(item => {
            resourceData.forEach(v => {
              if (v.hour == item.hour.split(':')[0]) {
                v.pmtwo = item.pmtwo
                v.pmten = item.pmten
                v.speed = item.speed
              }
            })
          })
          this.resourceData = resourceData;
          this.renderChart(this.resourceData, this.activeKey)
        }
      })
    },
    renderChart(resourceData, key) {
      // 基于准备好的dom，初始化echarts实例
      let typeList = ['pmtwo', 'pmten', 'speed']
      let myChart = this.$echarts.init(
        document.getElementById("hours-data-analysis")
      );
      // 蓝色
      let blueIcon =
        '<svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .564)" fill="none" fill-rule="evenodd"><circle stroke="#57F7FF" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#57F7FF" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      blueIcon = encodeURIComponent(blueIcon); // 转译
      blueIcon = "data:image/svg+xml;utf8," + blueIcon; // 添加url前缀
      blueIcon = "image://" + blueIcon; // 添加ECharts需要的前缀
      // 绘制图表
      myChart.setOption({
        tooltip: {

        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          interval: 10,
          data: resourceData.map(item => item.hour),
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#1c5790"
            },
          },
          axisTick: {
            show: false,
            interval: 4,
          },
          //   axisTick: {
          //     show: false
          //   },
          axisLabel: {
            margin: 8,
            color: '#ffffff',
            fontSize: 12,
            lineHeight: 14
          },
          splitLine: {
            show: false,
            // lineStyle: {
            //   color: "rgba(255, 255, 255, 0.05)",
            // },
          },
        },
        grid: {
          //   left: "3%",
          //   right: "4%",
          //   bottom: "3%",
          //   top: "5%",
          top: 25,
          right: 15,
          bottom: 0,
          left: 10,
          containLabel: true
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            lineStyle: {
              // 设置x轴颜色
              color: "#D2D2D2"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#135999'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            data: resourceData.map(item => item[typeList[key]]),
            type: "line",

            // areaStyle: { color: "rgba(87,247,255,0.5)" },
            symbolSize: 10,
            symbol: blueIcon,
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: false
                },
                borderColor: "rgba(87,247,255,0.5)", // 拐点边框颜色
                lineStyle: {
                  //   width: 5, // 设置线宽
                  //   type: "solid", //'dotted'虚线 'solid'实线
                  color: 'rgba(87,247,255,0.8)',

                  type: 'dashed'
                }
              },


            },
            hoverAnimation: false,
            areaStyle: {
              opacity: 0.3,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    // color: '#d36218'
                    // color: '#138EFF',
                    color: '#46c8d2'
                  },
                  {
                    offset: 0.3,
                    // color: '#d36218'
                    color: '#46c8d2'
                  },
                  {
                    offset: 1,
                    color: '#061924'
                  }
                ]
              }
            }
            // itemStyle: {
            //   normal: {
            //     opacity: 0
            //   },
            //   emphasis: {
            //     borderColor: '#ffffff',
            //     borderWidth: 2,
            //     opacity: 1
            //   }
            // },

          }
        ],
        color: ["rgba(87,247,255,1)"]
      });

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

  },
  watch: {
    activeKey(v) {
      this.renderChart(this.resourceData, v)
    }
  }
};
</script>